.ar .image-layer {
  background-image: url(../src/assets/shapes/vegee.jpg);
  background-color: rgba(0, 0, 0, 0.5);
  background-position: center center;
  object-fit: cover;
  height: 80vh;
}


.layercontent {
  background-color: #fff;
  width: 320px;
  height: 320px;
  padding: 30px;
  border-radius: 50%;
  /* position: absolute;
  top: 50%;
  right: 0%;
  translate: -50% -50%;
  display: flex;
  justify-content: center;
  align-items: center; */
  padding: 3px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.image-layerr {
  background-image: url(../src/assets/shapes/frozenc.jpg);
  background-color: rgba(0, 0, 0, 0.5);
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  height: 80vh;
}
.ar .aboutimagesection {
  background-image: url(../src/assets/shapes/pikaso_edit.jpeg);
  background-position: center center;
  background-size: cover;
  object-fit: cover;
}
.en .aboutimagesection {
  background-image: url(../src/assets/shapes/pikaso_edit2.png);
  background-position: center center;
  background-size: cover;
  object-fit: cover;
}
.image-layerrr {
  background-image: url(../src/assets/shapes/potatooo.png);
  background-color: rgba(0, 0, 0, 0.5);
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  height: 80vh;
}
.ar .layer {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-inline: 50px;
}
.ar .page-header__bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  background: rgba(var(--thm-primary-rgb), 0.85);
  content: "";
  z-index: -2;
}
.en .page-header__bg::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 50%;
  background: rgba(var(--thm-primary-rgb), 0.85);
  content: "";
  z-index: -2;
}
.en .layer {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-inline: 50px;
}
.en .image-layer {
  background-image: url(../src/assets/shapes/vegee.jpg);

  background-color: rgba(0, 0, 0, 0.5);
  background-position: center center;
  object-fit: cover;
  height: 80vh;
}
.en .image-layerr {
  background-image: url(../src/assets/shapes/frozenc.jpg);
  background-color: rgba(0, 0, 0, 0.5);
  background-position: center center;
  object-fit: cover;
  background-size: cover;
  height: 80vh;
}

.shape00 {
  background-color: red;
  width: 300px;
  height: 300px;
}

.ar .main-menu .main-menu__list > li + li,
.stricky-header .main-menu__list > li + li {
  margin-right: 58px !important;
  margin-left: auto;
}
.en .main-menu .main-menu__list > li + li,
.stricky-header .main-menu__list > li + li {
  margin-left: 58px !important;
}
.ar .main-slider-one .shape2 {
  right: 190px;
  width: 30%;
  top: 90px;
}
.en .main-slider-one .shape2 {
  left: 190px;
  width: 30%;
  top: 90px;
}
.logo {
  width: 25% !important;
}

.HeroLogo {
  width: 30%;
}

@media only screen and (max-width: 844px) {
  .HeroLogo {
    width: 500px;
  }
}

@media only screen and (min-width: 844px) and (max-width: 1200px) {
  .HeroLogo {
    width: 200px;
  }
}
@media only screen and (min-width: 1510px) {
  .ar .main-slider-one .shape2 {
    right: 190px;
    width: 30%;
    top: 90px;
  }
  .en .main-slider-one .shape2 {
    left: 190px;
    width: 30%;
    top: 90px;
  }
  .logo {
    width: 25% !important;
  }
}
.ar .about-one__bg-content .img-box .inner {
  position: relative;
  display: block;
  overflow: hidden;
  width: 300px;
  border-radius: 50%;
  border-bottom-left-radius: 0;
}
.about-one__bg-content .img-box {
  direction: ltr;
}

.en .about-one__bg-content .img-box .inner {
  transform: scalex(-1);
  position: relative;
  display: block;
  overflow: hidden;
  width: 300px;
  border-radius: 50%;
  border-bottom-left-radius: 0;
}
.ar .main-slider__nav {
  position: absolute;
  top: 40%;
  left: 70px !important;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  transform-origin: bottom;
  transform-style: preserve-3d;
  line-height: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transform: scaleX(1) translateX(0px);
  z-index: 105;
}
.en .main-slider__nav {
  position: absolute;
  top: 40%;
  right: 70px !important;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  transform-origin: bottom;
  transform-style: preserve-3d;
  line-height: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transform: scaleX(1) translateX(0px);
  z-index: 105;
}
.ar .main-slider-one__content .btn-box {
  position: relative;
  display: block;
  /* margin-top: 80px; */
  opacity: 0;
  left: 300px;
  -webkit-transform: perspective(400px) rotateY(0deg) translateX(80px);
  -ms-transform: perspective(400px) rotateY(0deg) translateX(80px);
  transform: perspective(400px) rotateY(0deg) translateX(80px);
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  line-height: 0;
  z-index: 10;
}

.ar .thm-btn i::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  top: 1px;
  font-weight: 700;
  rotate: 180deg;
  margin-right: 5px;
}
.en .thm-btn i::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  top: 1px;
  font-weight: 700;

  margin-right: 5px;
}
.ar .main-slider-two__outer-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0px;
  right: 0px;
  bottom: 0px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  width: max-content;
  text-align: center;
  line-height: 0;
  background: #ffffff;
  z-index: 5;
  padding: 105px 0px 30px;
  padding-left: 19px;
  padding-right: 20px;
}
.en .main-slider-two__outer-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0px;
  left: 0px;
  bottom: 0px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  width: max-content;
  text-align: center;
  line-height: 0;
  background: #ffffff;
  z-index: 5;
  padding: 105px 0px 30px;
  padding-left: 19px;
  padding-right: 18px;
}

.tabs-content__list {
  position: relative;
  display: block;
  max-width: 285px;
  width: 100%;
  z-index: 5;
  margin-right: auto;
}
.en .about-one__bg {
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 120px;
  right: 56%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  z-index: 1;
}
.ar .about-one__bg {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 120px;
  left: 56%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  z-index: 1;
}

@media only screen and (max-width: 1198px) {
  .ar .about-one__bg {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 120px;
    left: 0%;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    z-index: 1;
  }
  .en .about-one__bg {
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 120px;
    right: 0%;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
    z-index: 1;
  }
}
.ar .about-one__content-bottom .client-info {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 45px;
}
.en .about-one__content-bottom .client-info {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 45px;
}

.en .about-one__bg-content {
  position: absolute;
  bottom: 0;
  right: -55px;
  z-index: 1;
}
.ar .about-one__bg-content {
  position: absolute;
  bottom: 0;
  left: -55px;
  z-index: 1;
}

.ar .excellent-work-one__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: 1;
}
.en .excellent-work-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 50%;
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: 1;
}
.ar .contact-one .shape1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  rotate: 180deg;
}
.en .contact-one .shape1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.ar .contact-one .shape2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  rotate: 180deg;
}
.en .contact-one .shape2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.ar .main-slider-one .image-layer::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  background: rgb(241, 243, 242);
  background: linear-gradient(
    90deg,
    rgba(241, 243, 242, 1) 0%,
    rgba(241, 243, 242, 0.9724264705882353) 38%,
    rgba(241, 243, 242, 0) 56%,
    rgba(241, 243, 242, 0) 63%
  );
  content: "";
  z-index: -1;
}
.ar .about-one__bg-content .content-box {
  position: absolute;
  display: flex;
  align-items: center;
  right: 7px;
  bottom: 0;
  left: -7px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px rgb(0, 0, 0, 0.1);
  border-radius: 10px;
  border-top-left-radius: 0px;
  padding: 24px 17px 24px;
  z-index: 1;
}
.en .about-one__bg-content .content-box {
  position: absolute;
  display: flex;
  align-items: center;
  left: 7px;
  bottom: 0;
  right: -7px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 0px rgb(0, 0, 0, 0.1);
  border-radius: 10px;
  border-top-left-radius: 0px;
  padding: 24px 17px 24px;
  z-index: 1;
}
.about-one__bg-content .content-box::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 85px;
  background: var(--thm-base);
  content: "";
  z-index: -1;
}
.ar .about-one__bg-content .content-box .text-box {
  position: relative;
  display: block;
  margin-right: 35px;
}
.en .about-one__bg-content .content-box .text-box {
  position: relative;
  display: block;
  margin-left: 35px;
}
.en .excellent-work-one__bg .content-box {
  direction: ltr;
}
.en .excellent-work-one__bg .content-box {
  flex-direction: row;
  text-align: center;
  direction: ltr;
}
.ar .excellent-work-one__bg .content-box {
  direction: rtl;
}
.ar .excellent-work-one__bg .content-box {
  flex-direction: row;
  text-align: center;
  direction: rtl;
}

.ar .contact-one__img {
  position: absolute;
  bottom: -10px;
  left: 36px;
  right: auto;
}
.en .contact-one__img {
  position: absolute;
  bottom: -10px;
  right: 36px;
  left: auto;
}

.ar .blog-one__single-content-bottom ul li .btn-box a span::before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 15px;
  font-weight: 700;
  top: 2px;
  rotate: 180deg;
  margin-right: 9px;
}

/* .ar .main-slider-one .shape1 {
    position: absolute;
    top: 0px;
    right: 80px;
    opacity: 0;
    z-index: 2;
    transform: translateY(-10%);
    -webkit-transition: all 1000ms ease;
    -moz-transition: all 1000ms ease;
    -ms-transition: all 1000ms ease;
    -o-transition: all 1000ms ease;
    transition: all 1000ms ease;
    rotate: 180deg;
} */
/* .en .main-slider-one .shape1 {
    position: absolute;
    top: 0px;
    left: 80px;
    opacity: 0;
    z-index: 2;
    transform: translateY(-10%);
    -webkit-transition: all 1000ms ease;
    -moz-transition: all 1000ms ease;
    -ms-transition: all 1000ms ease;
    -o-transition: all 1000ms ease;
    transition: all 1000ms ease;
    rotate: 180deg;
}  */

.ar .main-slider-one .shape1 {
  position: absolute;
  top: 0px;
  right: 80px;
  opacity: 0;
  z-index: 2;
  transform: translateY(-10%);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
.ar .main-slider-one .swiper-slide-active .shape1 {
  opacity: 0.3;
  transform: translateY(0);
  -webkit-transition-delay: 2000ms;
  -moz-transition-delay: 2000ms;
  -ms-transition-delay: 2000ms;
  -o-transition-delay: 2000ms;
  transition-delay: 2000ms;
}

.main-slider-one__content .hamadaBtn {
  width: fit-content;
  margin: 0 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1550px) {
  .ar .main-slider-one__content {
    padding-right: 190px;
  }

  .en .main-slider-one__content {
    padding-left: 175px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1800px) {
  .ar .main-slider-one__content {
    padding-right: 100px;
  }

  .en .main-slider-one__content {
    padding-left: 175px;
  }
  .ar .hamadaBtn {
    margin: 0 60px;
  }
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  /* color: #212529; */
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.25rem;
}
.dropdown-menu .dropdown-item > li > a:hover {
  background-image: none !important;
  background-color: #000 !important;
}
.dropdown-content a:hover {
  background-color: transparent !important;
}
.ar .main-header__contact-info li .inner .text-box {
  position: relative;
  display: block;
  margin-right: 10px;
}
.en .main-header__contact-info li .inner .text-box {
  position: relative;
  display: block;
  margin-left: 10px;
}
.ar .main-header__contact-info li {
  position: relative;
  display: inline-block;
  margin-left: 65px;
}
.en .main-header__contact-info li {
  position: relative;
  display: inline-block;
  margin-right: 65px;
}

.ar .title img {
  right: 35px;
}
.en .main-slider-one__content .btn-box {
  text-align: end;
}
.ar .main-slider-one__content .btn-box {
  text-align: center;
  margin-top: 34px;
  display: none;
}
.en .main-slider-one__content .title h2 {
  color: var(--thm-black);
  font-size: 50px;
  line-height: 1.3em;
  font-weight: 800;
  text-align: start;
}
.ar .main-slider-one__content .title h2 {
  color: var(--thm-black);
  font-size: 50px;
  line-height: 1.3em;
  font-weight: 800;
  text-align: justify;
}
.main-slider-one__content .hamadaBtn {
  display: none;
}
/* @media only screen and (min-width: 992px) and (max-width: 1200px) {
  .ar .main-slider-one__content {
    padding-right: 140px;
  }
  .main-slider-one__content .hamadaBtn {
   
    margin: 0;
  }
}


@media only screen and (max-width: 992px){

    .main-slider-one__content .hamadaBtn {
    display: none;
    }
  } */
